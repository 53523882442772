import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { getCurrentUser } from "../api";
import VendorDetails from "./VendorDetails";

function VendorHome() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;
    async function fetchUser() {
      const currentUser = await getCurrentUser();

      if (currentUser && currentUser.userType === "Vendor") {
        if (!isCancelled) {
          setUser(currentUser);
          setLoading(false);
        }
      }
    }

    fetchUser();

    return () => {
      isCancelled = true;
    };
  }, []);

  if (loading && !user) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h1" gutterBottom sx={{ textAlign: "center", pb: 4 }}>
        Welcome {user.name}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* <Typography
            variant="body1"
            gutterBottom
            sx={{ textAlign: "center", fontStyle: "italic" }}
          >
            {user.email}
          </Typography> */}
        </Grid>
        <Grid item xs={12}>
          <VendorDetails vendor={user} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default VendorHome;
