import "./App.css";

import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import axios from "axios";

import Navigation from "./components/Navigation";

import { AuthProvider } from "./contexts/AuthContext";

import VendorLogin from "./components/VendorLogin";

import VendorHome from "./components/VendorHome";

import CorporationLogin from "./components/CorporationLogin";

import CorporationHome from "./components/CorporationHome";

import HomePage from "./components/HomePage";

import PrivateRoute from "./routing/PrivateRoute";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import UserRegistration from "./components/UserRegistration";

import VendorProfile from "./components/VendorProfile";

import CorporationProfile from "./components/CorporationProfile";

import AdministratorHome from "./components/AdministratorHome";

import AdministratorLogin from "./components/AdministratorLogin";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/layout/Footer";

const token = localStorage.getItem("token");

const theme = createTheme({
  palette: {
    primary: {
      main: "#3e3e3e",
    },
    secondary: {
      main: "#8c8c8c",
    },
    background: {
      default: "#f5f5f5",
      paper: "#fff",
    },
    text: {
      primary: "#333",
      secondary: "#555",
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 500,
      fontSize: "2.5rem",
    },
    h2: {
      fontWeight: 500,
      fontSize: "2rem",
    },
    h3: {
      fontWeight: 500,
      fontSize: "1.75rem",
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.5rem",
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.25rem",
    },
    h6: {
      fontWeight: 500,
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "1rem",
    },
    subtitle2: {
      fontSize: "0.875rem",
    },
    body1: {
      fontSize: "0.875rem",
    },
    body2: {
      fontSize: "0.75rem",
    },
  },
  spacing: 8,
  shadows: Array(25).fill("0px 3px 5px 2px rgba(0, 0, 0, 0.10)"),
});

if (token) {
  // If there's a token in local storage, use it in the headers of future requests
  axios.defaults.headers.common["x-auth-token"] = token;
} else {
  // Otherwise, delete the token from the headers
  delete axios.defaults.headers.common["x-auth-token"];
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="/register" element={<UserRegistration />} />
            <Route path="/vendors/login" element={<VendorLogin />} />
            <Route path="/corporations/login" element={<CorporationLogin />} />
            <Route
              path="/corporations/home"
              element={
                <PrivateRoute>
                  <CorporationHome />
                </PrivateRoute>
              }
            />
            <Route
              path="/corporations/profile"
              element={
                <PrivateRoute>
                  <CorporationProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/vendors/home"
              element={
                <PrivateRoute>
                  <VendorHome />
                </PrivateRoute>
              }
            />
            <Route
              path="/vendors/profile"
              element={
                <PrivateRoute>
                  <VendorProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/administrators/login"
              element={<AdministratorLogin />}
            />
            <Route
              path="/administrators/home"
              element={
                <PrivateRoute>
                  <AdministratorHome />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<HomePage />} />
          </Routes>
          <Footer />
        </Router>
      </AuthProvider>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
