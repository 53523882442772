import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  CircularProgress,
  Container,
  InputLabel,
  Typography,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";

import { updateVendorDetails } from "../api";
import { getCurrentUser } from "../api";
// import { AlertMessage } from "./AlertMessage";
import { UiAlert } from "../util/ui-alert";
import { useNavigate } from "react-router-dom";

function VendorProfile() {
  const [vendor, setVendor] = useState({});
  const [loading, setLoading] = useState(true);
  const [naicsError, setNaicsError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      const currentUser = await getCurrentUser();
      if (currentUser && currentUser.userType === "Vendor") {
        setVendor(currentUser);
        setVendorDetails(currentUser);
        setLoading(false);
      }
    }
    fetchUser();
  }, []);

  const [vendorDetails, setVendorDetails] = useState(vendor);

  const validateNAICS = (value) => {
    if (value.length === 0) {
      setNaicsError("NAICS field required");
    } else {
      setNaicsError("");
    }
  };

  const handleChange = (event) => {
    const { name, type, value, files } = event.target;
    let newValue = type === "checkbox" ? event.target.checked : value;
    if (files) {
      if (files.length > 0) {
        newValue = files[0];
      } else {
        return;
      }
    }
    setVendorDetails((prevVendorDetails) => ({
      ...prevVendorDetails,
      [name]: newValue,
    }));
  };

  const handleNaicsChange = (newNaics) => {
    setVendorDetails({ ...vendorDetails, naics: newNaics });
    validateNAICS(newNaics);
  };

  const handleFileChange = (event) => {
    const name = event.target.name;
    setVendorDetails({
      ...vendorDetails,
      [name]: event.target.files[0],
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!!naicsError || vendorDetails.naics.length === 0) {
      return validateNAICS(vendorDetails.naics);
    }
    const formData = new FormData();
    Object.keys(vendorDetails).forEach((key) => {
      if (key === "certificates" || key === "qualifications") {
        if (vendorDetails[key] instanceof File) {
          formData.append(key, vendorDetails[key]);
        }
      } else if (key === "naics") {
        formData.append(key, JSON.stringify(vendorDetails[key]));
      } else if (key === "us_citizen") {
        formData.append(key, vendorDetails[key] ? "true" : "false");
      } else {
        formData.append(key, vendorDetails[key]);
      }
    });

    try {
      const updatedVendor = await updateVendorDetails(formData);
      setVendor(updatedVendor);
      UiAlert.showSuccess(`Profile updated successfully`);
      navigate("../vendors/home");
    } catch (error) {
      console.error(error);
      UiAlert.showErrors(
        `Failed to update profile: ${error?.response?.data?.message}`
      );
    }
  };

  return (
    <Container sx={{ py: 4 }}>
      <Grid container justifyContent={"center"}>
        <Typography variant="h5" gutterBottom sx={{ pb: 3 }}>
          Profile
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Box sx={{ pb: 4 }}>
          <Grid container spacing={4} justifyContent="center">
            {loading ? (
              <Grid container item xs={12} justifyContent="center">
                <CircularProgress></CircularProgress>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="name"
                    value={vendorDetails?.name}
                    onChange={handleChange}
                    label="Company Name"
                    required
                    sx={{ width: "300px" }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="email"
                    value={vendorDetails?.email}
                    onChange={handleChange}
                    label="Email"
                    required
                    sx={{ width: "300px" }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    multiline
                    name="business_address"
                    value={vendorDetails?.business_address}
                    onChange={handleChange}
                    label="Business Address"
                    required
                    sx={{ width: "300px" }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="website"
                    value={vendorDetails?.website}
                    onChange={handleChange}
                    label="Website"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    multiline
                    name="description"
                    value={vendorDetails?.description}
                    onChange={handleChange}
                    label="Company Description"
                    fullWidth
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="established_in"
                    value={vendorDetails?.established_in}
                    onChange={handleChange}
                    label="Established In"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="company_owner"
                    value={vendorDetails?.company_owner}
                    onChange={handleChange}
                    label="Company Owner"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <MuiChipsInput
                    label="NAICS*"
                    value={vendorDetails?.naics}
                    onChange={handleNaicsChange}
                    sx={{ width: "300px" }}
                    error={!!naicsError}
                    helperText={naicsError}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <Box>
                    {vendorDetails?.certificates && (
                      <Box sx={{ pb: 2, textAlign: "center" }} component="div">
                        <a
                          href={vendorDetails?.certificatesDetails?.[0]?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Certificates
                        </a>
                      </Box>
                    )}
                    <InputLabel htmlFor="certificates">
                      Upload Certificates
                    </InputLabel>
                    <input
                      accept=".pdf,.doc,.docx,.jpg,.png"
                      name="certificates"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <Box>
                    {vendorDetails?.qualifications && (
                      <Box sx={{ pb: 2, textAlign: "center" }} component="div">
                        <a
                          href={vendorDetails?.qualificationsDetails?.[0]?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Statement of Qualifications
                        </a>
                      </Box>
                    )}
                    <InputLabel htmlFor="qualifications">
                      Upload Statement of Qualifications
                    </InputLabel>

                    <input
                      accept=".pdf,.doc,.docx,.jpg,.png"
                      name="qualifications"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vendorDetails?.us_citizen || false}
                        onChange={handleChange}
                        name="us_citizen"
                      />
                    }
                    label="Are you a US Citizen?"
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    sx={{ width: "200px" }}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </form>
    </Container>
  );
}

export default VendorProfile;
