import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const toastOption = {
  position: "bottom-left",
  autoClose: 2000,
  theme: "colored",
};
export class UiAlert {
  static showErrors(messages) {
    toast.error(messages, toastOption);
  }

  static showWarn(message) {
    toast.warn(message, toastOption);
  }

  static showInfo(message) {
    toast.info(message, toastOption);
  }

  static showSuccess(message) {
    toast.success(message, toastOption);
  }
}
