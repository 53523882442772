import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InputLabel,
  Grid,
  TextField,
  Button,
  Box,
  CircularProgress,
  Container,
  Input,
  Typography,
} from "@mui/material";
import { updateCorporationDetails, getCurrentUser } from "../api";
// import { AlertMessage } from "./AlertMessage";
import { UiAlert } from "../util/ui-alert";

const API_BASE_URL = process.env.REACT_APP_API_URL;

function CorporationProfile() {
  const [corporation, setCorporation] = useState({});
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      let currentUser = await getCurrentUser();
      if (currentUser && currentUser.userType === "Corporation") {
        currentUser = {
          ...currentUser,
          logoDetails: currentUser.logo
            ? {
                name: currentUser.logo.replace(/{|}/g, ""),
                url: `${API_BASE_URL}/files/${currentUser.logo.replace(
                  /{|}/g,
                  ""
                )}`,
              }
            : null,
        };
        setCorporation(currentUser);
        setLoading(false);
      }
    }

    fetchUser();
  }, []);

  const handleFileChange = (event) => {
    setLogo(event.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCorporation((prevCorporationDetails) => ({
      ...prevCorporationDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let formData = new FormData();
      Object.keys(corporation).forEach((key) =>
        formData.append(key, corporation[key])
      );
      if (logo) {
        formData.append("logo", logo);
      }

      const { corporation: updatedCorporation } =
        await updateCorporationDetails(formData);
      updatedCorporation.logoDetails = logo
        ? {
            name: logo.name,
            url: URL.createObjectURL(logo),
          }
        : corporation.logoDetails;
      setCorporation(updatedCorporation);
      setLogo(null);
      UiAlert.showSuccess(`Profile updated successfully`);
      navigate("../corporations/home");
    } catch (error) {
      console.error(error);
      UiAlert.showErrors(
        `Failed to update profile: ${error?.response?.data?.message}`
      );
    }
  };

  return (
    <Container sx={{ py: 4 }}>
      <Grid container justifyContent={"center"}>
        <Typography variant="h5" gutterBottom sx={{ pb: 3 }}>
          Profile
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4} justifyContent="center">
          {loading ? (
            <Grid container item xs={12} justifyContent="center">
              <CircularProgress></CircularProgress>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} container justifyContent="center">
                <TextField
                  disabled
                  name="name"
                  value={corporation?.name}
                  onChange={handleChange}
                  label="Name"
                  required
                  sx={{ width: "300px" }}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <TextField
                  disabled
                  name="email"
                  value={corporation?.email}
                  onChange={handleChange}
                  label="Email"
                  required
                  sx={{ width: "300px" }}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <TextField
                  multiline
                  rows={5}
                  name="description"
                  value={corporation?.description}
                  onChange={handleChange}
                  label="Description"
                  required
                  sx={{ width: "300px" }}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <TextField
                  name="website"
                  value={corporation?.website}
                  onChange={handleChange}
                  label="Website"
                  required
                  sx={{ width: "300px" }}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <TextField
                  name="services"
                  value={corporation?.services}
                  onChange={handleChange}
                  label="Services/Products Interested In"
                  required
                  sx={{ width: "300px" }}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Box>
                  <InputLabel htmlFor="upload-logo">Upload Logo</InputLabel>
                  <Input
                    id="upload-logo"
                    type="file"
                    accept="image/*"
                    name="logo"
                    onChange={handleFileChange}
                    fullWidth
                    sx={{ width: "300px", py: 4 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Box>
                  {corporation?.logoDetails && !logo && (
                    <img
                      src={corporation.logoDetails.url}
                      alt="Logo"
                      style={{ width: "150px", height: "150px" }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ width: "200px" }}
                >
                  Save
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Container>
  );
}

export default CorporationProfile;
