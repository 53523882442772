import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Input,
  InputLabel,
  Grid,
  Checkbox,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";

// import { AlertMessage } from "./AlertMessage";
import { UiAlert } from "../util/ui-alert";
import { Link } from "react-router-dom";
import {
  vendorRegistration,
  corporationRegistration,
  administratorRegistration,
} from "../api";

function UserRegistration() {
  const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    userType: "vendor",
    description: "",
    isUSCitizen: false,
    certificates: [],
    naics: [],
    businessAddress: "",
    qualifications: null,
    website: "",
    companyOwner: "",
    establishedIn: "",
    services: "",
    logo: null,
  });

  const [success, setSuccess] = useState(false);
  const [passError, setPassError] = useState("");
  const [naicsError, setNaicsError] = useState("");

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, [event.target.name]: event.target.value });
    validatePassword(value);
  };

  const validatePassword = (value) => {
    if (!passPattern.test(value)) {
      setPassError(
        "Password must be at least 8 characters, contain at least one uppercase letter, one lowercase letter, and one number."
      );
    } else {
      setPassError("");
    }
  };

  const validateNAICS = (value) => {
    if (value.length === 0) {
      setNaicsError("NAICS field required");
    } else {
      setNaicsError("");
    }
  };

  const handleFileChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.files[0] });
  };

  const handleCheckBoxChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const handleNaicsChange = (newNaics) => {
    setFormData({ ...formData, naics: newNaics });
    validateNAICS(newNaics);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!!passError) {
      return;
    }
    if (
      formData.userType === "vendor" &&
      (!!naicsError || formData.naics.length === 0)
    ) {
      return validateNAICS(formData.naics);
    }

    // Create a new FormData instance
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "certificates" || key === "qualifications") {
        // Check if the file exists
        if (formData[key] instanceof File) {
          data.append(key, formData[key]);
        }
      } else if (key === "naics") {
        if (formData[key]) {
          formData[key].forEach((naicsCode, index) => {
            data.append(`${key}[${index}]`, naicsCode);
          });
        }
      } else {
        // Append other fields to FormData
        data.append(key, formData[key]);
      }
    });

    try {
      let response;

      if (formData.userType === "vendor") {
        // Send FormData instead of individual fields

        response = await vendorRegistration(data);
      } else if (formData.userType === "administrator") {
        // Send FormData instead of individual fields
        response = await administratorRegistration(data);
      } else {
        // Send FormData instead of individual fields
        response = await corporationRegistration(data);
      }

      UiAlert.showSuccess(`Success: ${response?.message}`);
      setSuccess(true);
    } catch (error) {
      console.error(error);
      UiAlert.showErrors(
        `Something went wrong: ${
          error?.response?.data?.message || error?.message
        }`
      );
      console.error("Error registering user", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        py: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        height: "100vh",
        width: "100%",
      }}
    >
      {success ? (
        <section style={{ textAlign: "center" }}>
          <h1>Success!</h1>
          <p>
            <Link to={`/${formData.userType}s/login`}>Login</Link>
          </p>
        </section>
      ) : (
        <>
          <Typography variant="h4" gutterBottom>
            Registration
          </Typography>
          <Typography variant="h6" gutterBottom>
            Are you a Vendor or a Corporation?
          </Typography>
          <RadioGroup
            aria-label="userType"
            name="userType"
            value={formData.userType}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="vendor"
              control={<Radio />}
              label="Vendor"
            />
            <FormControlLabel
              value="corporation"
              control={<Radio />}
              label="Corporation"
            />
          </RadioGroup>
          {formData.userType === "administrator" && (
            <Box sx={{ mb: 4 }}>
              <TextField
                name="email"
                value={formData.email}
                onChange={handleChange}
                label="Email"
                type="email"
                required
                sx={{ width: "300px" }}
              />
              <TextField
                name="password"
                type="password"
                value={formData.password}
                onChange={handlePasswordChange}
                label="Password"
                required
                error={!!passError}
                helperText={passError}
                sx={{ width: "300px" }}
              />
              <Button type="submit" variant="contained" sx={{ width: "200px" }}>
                Register
              </Button>
            </Box>
          )}
          {formData.userType === "vendor" && (
            <Box sx={{ pb: 4 }}>
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    label="Company Name"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="companyOwner"
                    value={formData.companyOwner}
                    onChange={handleChange}
                    label="Company Owner"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="establishedIn"
                    value={formData.establishedIn}
                    onChange={handleChange}
                    label="Established In"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    label="Email"
                    type="email"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handlePasswordChange}
                    label="Password"
                    required
                    error={!!passError}
                    helperText={passError}
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    multiline
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    label="Company Description"
                    fullWidth
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                    label="Website"
                    placeholder="website.com"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    multiline
                    name="businessAddress"
                    value={formData.businessAddress}
                    onChange={handleChange}
                    label="Business Address"
                    required
                    sx={{ width: "300px", pb: 4 }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <MuiChipsInput
                    label="NAICS*"
                    value={formData?.naics}
                    onChange={handleNaicsChange}
                    sx={{ width: "300px" }}
                    error={!!naicsError}
                    helperText={naicsError}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Box>
                    <InputLabel htmlFor="upload-certificate">
                      Upload Certificates
                    </InputLabel>
                    <Input
                      id="upload-certificate"
                      type="file"
                      accept=".pdf"
                      multiple
                      name="certificates"
                      onChange={handleFileChange}
                      fullWidth
                      sx={{ width: "300px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Box>
                    <InputLabel htmlFor="upload-qualifications">
                      Upload Statement of Qualifications
                    </InputLabel>
                    <Input
                      id="upload-qualifications"
                      type="file"
                      accept=".pdf"
                      name="qualifications"
                      onChange={handleFileChange}
                      fullWidth
                      sx={{ width: "300px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.isUSCitizen}
                        name="isUSCitizen"
                        onChange={handleCheckBoxChange}
                      />
                    }
                    label="Are you a US Citizen?"
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{ width: "200px" }}
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          {formData.userType === "corporation" && (
            <Box sx={{ pb: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    label="Name"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    label="Email"
                    type="email"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handlePasswordChange}
                    label="Password"
                    required
                    error={!!passError}
                    helperText={passError}
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    multiline
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    label="Description"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                    label="Website"
                    placeholder="website.com"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <TextField
                    name="services"
                    value={formData.services}
                    onChange={handleChange}
                    label="Services/Products Interested In"
                    required
                    sx={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Box sx={{}}>
                    <InputLabel htmlFor="upload-logo">Upload Logo</InputLabel>
                    <Input
                      id="upload-logo"
                      type="file"
                      accept="image/*"
                      name="logo"
                      onChange={handleFileChange}
                      fullWidth
                      sx={{ width: "300px", py: 4 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{ width: "200px" }}
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default UserRegistration;
