import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getCurrentUser,
  getApplications,
  updateApplicationStatus,
} from "../api";
const API_BASE_URL = process.env.REACT_APP_API_URL;

function StyledTableCell(props) {
  return <TableCell {...props} sx={{ fontSize: 14 }} />;
}

function CorporationHome() {
  const [user, setUser] = useState(null);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    async function fetchUserAndApplications() {
      const currentUser = await getCurrentUser();
      setUser(currentUser);

      if (currentUser && currentUser.userType === "Corporation") {
        let applicationsData = await getApplications(currentUser.id);

        let transformedApplicationsData = applicationsData.map((a) => {
          return {
            ...a,
            certificatesDetails: a.certificates
              ? a.certificates.map((c) => {
                  return { name: c, url: `${API_BASE_URL}/files/${c}` };
                })
              : [],
            qualificationsDetails: a.qualifications
              ? a.qualifications.map((c) => {
                  return { name: c, url: `${API_BASE_URL}/files/${c}` };
                })
              : [],
          };
        });

        // Sort applications by 'slot' property
        transformedApplicationsData.sort((a, b) =>
          a.slot.localeCompare(b.slot)
        );

        if (!isCancelled) {
          setApplications(transformedApplicationsData);
        }
      }
      setLoading(false);
    }

    fetchUserAndApplications();

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleClickOpen = (application) => {
    setSelectedApplication(application);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = async (applicationId) => {
    await updateApplicationStatus(applicationId, "accepted");
    setApplications(
      applications.map((app) =>
        app.id === applicationId ? { ...app, status: "accepted" } : app
      )
    );
  };

  const handleDecline = async (applicationId) => {
    await updateApplicationStatus(applicationId, "declined");
    setApplications(
      applications.map((app) =>
        app.id === applicationId ? { ...app, status: "declined" } : app
      )
    );
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h1" gutterBottom sx={{ textAlign: "center", pb: 4 }}>
        Welcome {user.name}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ pb: 2 }}>
            Applications
          </Typography>
          <TableContainer sx={{ fontSize: 14 }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <strong>Vendor Name</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Company Name</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Starts</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>NAICS</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Certificates</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Statement of Qualifications</strong>
                  </StyledTableCell>
                  {/* <StyledTableCell>
                    <strong>Booking</strong>
                  </StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {applications.map((application) => (
                  <TableRow key={application.id}>
                    <StyledTableCell
                      onClick={(event) => {
                        event.preventDefault();
                        handleClickOpen(application);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        href="#"
                        style={{ textDecoration: "underline", color: "blue" }}
                      >
                        {application.vendorname}
                      </a>
                    </StyledTableCell>

                    <StyledTableCell>
                      {application.company_owner}
                    </StyledTableCell>

                    <StyledTableCell>
                      {application.slot.substring(
                        0,
                        application.slot.length - 3
                      )}
                    </StyledTableCell>

                    <StyledTableCell>
                      {application.naics?.join(", ")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {application.certificatesDetails?.map((certificate) => (
                        <a
                          href={certificate.url}
                          key={certificate.name}
                          rel="noreferrer"
                          target="_blank"
                        >
                          View Certificates
                        </a>
                      ))}
                    </StyledTableCell>
                    <StyledTableCell>
                      {application.qualificationsDetails?.map(
                        (qualification) => (
                          <a
                            href={qualification.url}
                            key={qualification.name}
                            rel="noreferrer"
                            target="_blank"
                          >
                            View Qualifications
                          </a>
                        )
                      )}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAccept(application.id)}
                        disabled={application.status !== "pending"}
                      >
                        Accept
                      </Button>
                      <Button
                        sx={{ ml: 1 }}
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDecline(application.id)}
                        disabled={application.status !== "pending"}
                      >
                        Decline
                      </Button>
                    </StyledTableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <strong>Vendor Name:</strong> {selectedApplication?.vendorname}
        </DialogTitle>
        <DialogContent dividers>
          {/* <DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div variant="">
                <strong>Email:</strong> {selectedApplication?.email}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div variant="body1">
                <strong>US Citizen:</strong>{" "}
                {selectedApplication?.us_citizen ? "Yes" : "No"}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div variant="body1">
                <strong>Business Address:</strong>{" "}
                {selectedApplication?.business_address}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div variant="body1">
                <strong>NAICS:</strong> {selectedApplication?.naics.join(", ")}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div variant="body1">
                <strong>Statement of Qualifications:</strong>{" "}
                <a
                  href={selectedApplication?.qualificationsDetails[0]?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Qualifications
                </a>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div variant="body1">
                <strong>Certificates:</strong>{" "}
                <a
                  href={selectedApplication?.certificatesDetails[0]?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Certificates
                </a>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div variant="body1">
                <strong>Website: </strong>
                <a
                  href={`https://${selectedApplication?.website}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {selectedApplication?.website}
                </a>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div variant="body1">
                <strong>Company Owner:</strong>{" "}
                {selectedApplication?.company_owner}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div variant="body1">
                <strong>Established In:</strong>{" "}
                {selectedApplication?.established_in}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div variant="body1">
                <strong>Company Description:</strong>{" "}
                {selectedApplication?.description}
              </div>
            </Grid>
          </Grid>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default CorporationHome;
