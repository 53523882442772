import React, { useState, useContext } from "react";
import { vendorLogin } from "../api";
import { TextField, Button, Box, Typography } from "@mui/material";
// import { AlertMessage } from "./AlertMessage";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { UiAlert } from "../util/ui-alert";

function VendorLogin() {
  const { setAuthState } = useContext(AuthContext);

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await vendorLogin(email, password, setAuthState);
      localStorage.setItem("token", response.token);
      UiAlert.showSuccess(`Success: ${response?.message}`);
      navigate("/vendors/home");
    } catch (error) {
      console.error(error);
      UiAlert.showErrors(
        `Something went wrong: ${
          error?.response?.data?.message || error?.message
        }`
      );
      console.error("Error logging in", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        height: "100vh",
        width: "100%",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Vendor Login
      </Typography>
      <TextField
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label="Email"
        required
        sx={{ width: "300px" }}
      />
      <TextField
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        label="Password"
        required
        sx={{ width: "300px" }}
      />
      <Button type="submit" variant="contained">
        Log in
      </Button>
    </Box>
  );
}

export default VendorLogin;
