import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import { AuthContext } from "../contexts/AuthContext";
import { logout } from "../api";
import ExitToAppIcon from "@mui/icons-material/ExitToApp"; // import Logout icon
import { validRoles } from "../util/auth";
import Image from "../images/logo.png";

function Navigation() {
  const { authState, setAuthState } = useContext(AuthContext);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleLogout = (event) => {
    event.preventDefault();
    logout(setAuthState);
  };

  const homeLocation = validRoles[authState.userType];

  let authenticatedMenuItems = [
    { text: "Home", path: `/${homeLocation}/home` },
    { text: "Logout", path: "/", onClick: handleLogout },
  ];

  // Condition to show Profile button only for "corporation" or "vendor"
  if (authState.userType === "Corporation" || authState.userType === "Vendor") {
    authenticatedMenuItems.splice(1, 0, {
      text: "Profile",
      path: `/${homeLocation}/profile`,
    });
  }

  const menuItems = [
    ...(authState.isAuthenticated
      ? authenticatedMenuItems
      : [
          { text: "Home", path: "/" },
          { text: "Sign Up", path: "/register" },
        ]),
  ];

  const drawer = (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={Link}
            to={item.path}
            onClick={(event) => {
              setDrawerOpen(false);
              item.onClick && item.onClick(event);
            }}
          >
            <Typography variant="body1">{item.text}</Typography>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Box component="img" src={Image} sx={{ height: 50 }} />
        </Box>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <ExitToAppIcon />
            </IconButton>
            {drawer}
          </>
        ) : (
          menuItems.map((item, index) => (
            <Button
              color="inherit"
              component={Link}
              to={item.path}
              onClick={(event) => item.onClick && item.onClick(event)}
              key={index}
            >
              {item.text}
            </Button>
          ))
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;
