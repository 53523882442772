import React, { useContext } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  Divider,
} from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { validRoles } from "../util/auth";

function HomePage() {
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const redirectHome = validRoles[authState.userType]
    ? `/${validRoles[authState.userType]}/home`
    : "/";
  return authState.isAuthenticated ? (
    <Navigate to={`${redirectHome}`} />
  ) : (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h1">
            Business Matchmaking
          </Typography>
        </Grid>

        <Grid item container justifyContent="center" spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/vendors/login")}
            >
              Sign in as Vendor
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/corporations/login")}
            >
              Sign in as Corporation
            </Button>
          </Grid>
        </Grid>

        <Grid item container alignItems="center">
          <Grid item xs={4}>
            <Divider />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">or</Typography>
          </Grid>
          <Grid item xs={4}>
            <Divider />
          </Grid>
          <Grid container item justifyContent={"center"}>
            <Box sx={{ pt: 4 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/register")}
              >
                Sign Up
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomePage;
