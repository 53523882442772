import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import moment from "moment";
import { fetchReservedSlots } from "../api";
import { UiAlert } from "../util/ui-alert";

export const TimeSlotSelector = ({
  setSelectedSlot,
  corporation,
  validateSlot,
}) => {
  const [slots, setSlots] = useState([]);
  const [reservedSlots, setReservedSlots] = useState([]);

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const reservedSlots = await fetchReservedSlots(corporation.id);
        setReservedSlots(reservedSlots);
        generateTimeSlots(reservedSlots);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSlots();
  }, [corporation.id]);

  const generateTimeSlots = (reservedSlots) => {
    const startOfDay = moment().set({ hour: 9, minute: 0 }); // 9:00 am
    const endOfDay = moment().set({ hour: 15, minute: 45 }); // 3:45 pm
    const timeSlotsInDay = [];
    let currentMoment = startOfDay.clone();

    while (currentMoment.isSameOrBefore(endOfDay)) {
      const slot = {
        display: currentMoment.format("HH:mm"),
        value: currentMoment.format("HH:mm"),
        selected: reservedSlots.some(
          (reservedSlot) =>
            reservedSlot.slot.substring(0, 5) === currentMoment.format("HH:mm")
        ),
      };
      timeSlotsInDay.push(slot);
      currentMoment = currentMoment.add(15, "minutes");
    }

    setSlots(timeSlotsInDay);
  };

  const bookSlot = (slot) => {
    const reservedSlot = validateSlot(slot);
    if (reservedSlot) {
      return UiAlert.showErrors(
        `Slot already reserved for ${reservedSlot.corporation_name}`
      );
    }
    const updatedSlots = slots.map((s) => ({
      ...s,
      selected:
        s.value === slot
          ? true
          : s.selected &&
            reservedSlots.some(
              (reservedSlot) => reservedSlot.slot.substring(0, 5) === s.value
            ),
    }));

    setSelectedSlot(slot);
    setSlots(updatedSlots);
  };

  return (
    <Box>
      <Typography variant="h5">Book a slot</Typography>
      <Typography variant="h6" sx={{ pb: 2 }}>
        Select a time slot to meet with{" "}
        <Typography sx={{ fontWeight: 700 }} variant="h6" component="span">
          {corporation.name}
        </Typography>
        {"."}
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Grid container spacing={1}>
          {slots.map((slot, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Button
                fullWidth
                disabled={slot.selected}
                variant={slot.selected ? "contained" : "outlined"}
                onClick={() => bookSlot(slot.value)}
              >
                {slot.display}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
