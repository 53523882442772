import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

function PrivateRoute({ children, redirectTo = "/", allowedUserType = null }) {
  const { authState } = useContext(AuthContext);

  const isAllowed =
    authState.isAuthenticated &&
    (!allowedUserType || authState.userType === allowedUserType);

  return isAllowed ? (
    <div className="content">{children}</div>
  ) : (
    <Navigate to={redirectTo} />
  );
}

export default PrivateRoute;
