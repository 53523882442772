import { Container, Grid, Link, Typography } from "@mui/material";
import React from "react";

const Footer = () => (
  <Container className="footer" fixed>
    <Grid container direction="column" alignItems="center">
      <Typography variant="body1" color="text.secondary">
        {" Powered by "}
        <Link
          href={`https://artedigital-mx.com`}
          target="_blank"
          rel="noopener noreferrer"
        >
          ArteDigital
        </Link>{" "}
      </Typography>
    </Grid>
  </Container>
);

export default Footer;
