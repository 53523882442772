import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  Link,
} from "@mui/material";
import { deleteApplications, getApplicationsByVendor } from "../api";
import { CorporationGrid } from "./CorporationGrid";

function StyledTableCell(props) {
  return <TableCell {...props} sx={{ fontSize: 14 }} />;
}

function VendorDetails({ vendor }) {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  useEffect(() => {
    let isCancelled = false;
    async function fetchUserAndApplications() {
      if (vendor) {
        let applicationsData = await getApplicationsByVendor();
        applicationsData = applicationsData.sort((a, b) =>
          a.slot.localeCompare(b.slot)
        );
        if (!isCancelled) {
          setApplications(applicationsData);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }

    fetchUserAndApplications();

    return () => {
      isCancelled = true;
    };
  }, [vendor]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onRemoveApplication = async (applicationId) => {
    await deleteApplications(applicationId);
    setApplications(applications.filter((a) => a.id !== applicationId));
  };

  const onAddApplication = async (application) => {
    setApplications([...applications, ...application]);
    const updatedApplications = await getApplicationsByVendor();
    updatedApplications.sort((a, b) => a.slot.localeCompare(b.slot));
    setApplications(updatedApplications);
  };

  const isDeleteDisabled = (application) => application.status === "accepted";

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="Corporations"
            sx={{
              color: "grey",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Applications"
            sx={{
              color: "grey",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        </Tabs>
      </AppBar>
      {value === 0 && applications.length < 5 ? (
        <Grid container sx={{ py: 2 }}>
          <Grid item xs={12}>
            <CorporationGrid
              vendor={vendor}
              addApplication={onAddApplication}
              applications={applications}
            />
          </Grid>
        </Grid>
      ) : (
        value === 0 && (
          <Grid container sx={{ py: 4 }}>
            <Grid item xs={12}>
              <Container>
                <Grid container>
                  <Typography variant="h6">{`No more new reservations can be sent. Maximum number of active applications`}</Typography>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        )
      )}
      {value === 1 && (
        <Grid container spacing={3} sx={{ py: 2 }}>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <Box sx={{ fontWeight: 700 }}>Corporation Name</Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box sx={{ fontWeight: 700 }}>Website</Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box sx={{ fontWeight: 700 }}>Status</Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box sx={{ fontWeight: 700 }}>Starts</Box>
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      <Box sx={{ fontWeight: 700 }}>Actions</Box>
                    </StyledTableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {applications.map((application) => (
                    <TableRow key={application.id}>
                      <StyledTableCell>
                        <Box sx={{ fontSize: 16 }}>
                          {application.corporation_name}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Link
                          sx={{ color: "blue" }}
                          href={`https://${application.corporation_website}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Box sx={{ fontSize: 16 }}>
                            {application.corporation_website}
                          </Box>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ fontSize: 16 }}>
                          <Chip
                            label={application.status}
                            className={`${application.status}`}
                            variant="outlined"
                            style={{ textTransform: "capitalize" }}
                          />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ fontSize: 16 }}>
                          {application.slot.substring(
                            0,
                            application.slot.length - 3
                          )}
                        </Box>
                      </StyledTableCell>
                      {/* <StyledTableCell>
                        <Button
                          color="warning"
                          disabled={isDeleteDisabled(application)}
                          onClick={() => onRemoveApplication(application.id)}
                        >
                          Delete
                        </Button>
                      </StyledTableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default VendorDetails;
