import React, { useEffect, useState } from "react";
import { ApplicationsGrid } from "./ApplicationsGrid";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { getVendors } from "../api";
// import { UiAlert } from "../util/ui-alert";

export const VendorApplicationsGrid = () => {
  const [selectedVendor, setSelectedVendor] = useState(null);

  const handleClose = () => {
    setSelectedVendor(null);
  };

  const [vendors, setVendors] = useState([]);

  // const modalStyle = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: "80%",
  //   maxWidth: 720,
  //   bgcolor: "background.paper",
  //   boxShadow: 24,
  //   p: 4,
  // };

  useEffect(() => {
    let isCancelled = false;

    async function fetchVendors() {
      const vendors = await getVendors();
      if (!isCancelled) {
        setVendors(vendors);
      }
    }

    fetchVendors();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Grid container spacing={3} sx={{ mb: 2 }}>
      {vendors.map((vendor) => (
        <Grid key={vendor.id} item xs={12} sm={4} md={3}>
          <Card onClick={() => setSelectedVendor(vendor)}>
            <CardContent>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar alt={vendor.name} src={vendor.logo} />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h5" component="div">
                      {vendor.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {vendor.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
          <Dialog
            open={selectedVendor === vendor}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <DialogContent style={{ paddingBottom: "20px" }}>
              <ApplicationsGrid vendor={vendor} />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      ))}
    </Grid>
  );
};

export default VendorApplicationsGrid;
