import React, { useEffect, useState } from "react";
import { TimeSlotSelector } from "./TimeSlotSelector";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Modal,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import { createApplication, getCorporations } from "../api";
// import { AlertMessage } from "./AlertMessage";
import { UiAlert } from "../util/ui-alert";

export const CorporationGrid = ({ vendor, applications, addApplication }) => {
  const [selectedCorporation, setSelectedCorporation] = useState(null);

  const [selectedSlot, setSelectedSlot] = useState(null);

  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleReadMore = (event) => {
    event.stopPropagation();
    setShowFullDescription(!showFullDescription);
  };

  const getDescription = (description) => {
    if (!showFullDescription && description.length > 100) {
      return description.substring(0, 100) + "...";
    } else {
      return description;
    }
  };

  const shouldShowReadMore = (description) => {
    return description && description.length > 100;
  };

  const validateCorpSelector = (corporation) => {
    const hasApplication = applications.find(
      (a) => a.corporation_id === corporation.id
    );
    if (hasApplication) {
      return UiAlert.showErrors("There is already a previous application!");
    }
    setSelectedCorporation(corporation);
  };

  const validateSlotSelector = (slot) => {
    const reservedSlot = applications.find(
      (a) => a.slot.substring(0, 5) === slot
    );
    return reservedSlot;
  };

  const handleClose = () => {
    setSelectedSlot(null);
    setSelectedCorporation(null);
  };

  const [corporations, setCorporations] = useState([]);

  const handleFormSubmit = async (e, corporation) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await createApplication(
        selectedSlot,
        token,
        vendor,
        corporation
      );
      if (response) {
        addApplication(response);
        UiAlert.showSuccess(
          `Success: We've sent your booking request to ${corporation.name}`
        );
        handleClose();
      }
    } catch (error) {
      console.error(error);
      UiAlert.showErrors(
        `Error creating application: ${error?.response?.data?.message}`
      );
      console.error("Error creating application", error);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 720,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflowY: "auto",
    p: 4,
  };

  useEffect(() => {
    let isCancelled = false;

    async function fetchCorporations() {
      const corporations = await getCorporations();
      if (!isCancelled) {
        setCorporations(corporations);
      }
    }

    fetchCorporations();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Grid container spacing={3}>
      {corporations.map((corporation) => (
        <Grid key={corporation.id} item xs={12} sm={6}>
          <Card
            onClick={() => (vendor ? validateCorpSelector(corporation) : null)}
          >
            <CardContent>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar
                      alt={corporation.name}
                      src={corporation.logoDetails?.[0]?.url}
                      variant="square"
                      style={{ objectFit: "scale-down" }}
                    />
                  </Grid>
                  <Grid item xs container spacing={2}>
                    <Grid item>
                      <Typography variant="h5" component="div">
                        {corporation.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div variant="body1" component="div">
                        <Box>
                          <strong>Looking for</strong>: {corporation.services}
                        </Box>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div variant="body1" color="text.secondary">
                        <strong>Email:</strong> {corporation.email}
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div variant="body1" color="text.secondary">
                        <strong>Website:</strong> {corporation.website}
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div variant="body1" color="text.secondary">
                        <>
                          <strong>Company Description:</strong>{" "}
                          {getDescription(corporation.description)}
                        </>

                        <Box>
                          {shouldShowReadMore(corporation.description) && (
                            <>
                              <a href="#" onClick={handleReadMore}>
                                {showFullDescription
                                  ? " Show Less"
                                  : " Read More"}
                              </a>
                            </>
                          )}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
          <Modal
            open={selectedCorporation === corporation}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box sx={modalStyle}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <button
                  className="button"
                  aria-label="close"
                  size="small"
                  onClick={handleClose}
                >
                  X
                </button>
              </div>
              <TimeSlotSelector
                setSelectedSlot={setSelectedSlot}
                corporation={corporation}
                validateSlot={validateSlotSelector}
              />
              {selectedSlot && (
                <Box sx={{ pt: 1 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={(e) => handleFormSubmit(e, corporation)}
                  >
                    Continue
                  </Button>
                </Box>
              )}
            </Box>
          </Modal>
        </Grid>
      ))}
    </Grid>
  );
};
