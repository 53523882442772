import { createContext, useEffect, useState } from "react";
import { logout } from "../api";
import jwt_decode from "jwt-decode";
import { getTokenDuration } from "../util/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userType: null,
  });
  useEffect(() => {
    const userToken = getCurrentUser();
    if (!userToken) return;
    const decodedJwt = jwt_decode(userToken);
    const tokenDuration = getTokenDuration(decodedJwt.exp * 1000);

    setAuthState({
      isAuthenticated: true,
      userType: decodedJwt?.userType,
    });

    const timer = setTimeout(() => {
      logout(setAuthState);
    }, tokenDuration);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const getCurrentUser = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      logout(setAuthState);
      return null;
    }
    return token;
  };
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <div className="app">{children}</div>
    </AuthContext.Provider>
  );
};
