import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getApplicationsById } from "../api";

export function ApplicationsGrid({ vendor }) {
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    async function fetchApplications() {
      const applicationsData = await getApplicationsById(vendor.id);

      if (!isCancelled) {
        setApplications(applicationsData);
      }
    }

    fetchApplications();

    return () => {
      isCancelled = true;
    };
  }, [vendor]);

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ pb: 2 }}>
        Applications
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Vendor Name</strong>
              </TableCell>
              {/* <TableCell>
                <strong>Application ID</strong>
              </TableCell> */}
              <TableCell>
                <strong>Corporation Name</strong>
              </TableCell>
              <TableCell>
                <strong>Corporation Website</strong>
              </TableCell>
              {/* <TableCell>
                <strong>Status</strong>
              </TableCell> */}
              <TableCell>
                <strong>Slot</strong>
              </TableCell>
              <TableCell>
                <strong>US Citizen</strong>
              </TableCell>
              <TableCell>
                <strong>Statement of Qualifications</strong>
              </TableCell>
              <TableCell>
                <strong>NAICS</strong>
              </TableCell>
              <TableCell>
                <strong>Vendor Certificates</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.map((application) => (
              <TableRow key={application.id}>
                <TableCell>{application.vendor_name}</TableCell>
                {/* <TableCell>{application.id}</TableCell> */}
                <TableCell>{application.corporation_name}</TableCell>
                <TableCell>{application.corporation_website}</TableCell>
                {/* <TableCell>{application.status}</TableCell> */}
                <TableCell>{application.slot}</TableCell>
                <TableCell>{application.us_citizen ? "Yes" : "No"}</TableCell>
                <TableCell>{application.qualifications}</TableCell>
                <TableCell>{application.naics?.join(", ")}</TableCell>
                <TableCell>{application.certificates}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedApplication && (
        <Dialog open={Boolean(selectedApplication)}>
          <DialogTitle>{selectedApplication.vendor_name}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>Application ID:</strong> {selectedApplication.id}
              <br />
              <strong>Corporation Name:</strong>{" "}
              {selectedApplication.corporation_name}
              <br />
              <strong>Corporation Website:</strong>{" "}
              {selectedApplication.corporation_website}
              <br />
              <strong>Status:</strong> {selectedApplication.status}
              <br />
              <strong>Slot:</strong> {selectedApplication.slot}
              <br />
              <strong>Vendor US Citizen:</strong>{" "}
              {selectedApplication.us_citizen ? "Yes" : "No"}
              <br />
              <strong>Vendor Qualifications:</strong>{" "}
              {selectedApplication.qualifications}
              <br />
              <strong>Vendor NAICS:</strong> {selectedApplication.naics}
              <br />
              <strong>Vendor Certificates:</strong>{" "}
              {selectedApplication.certificates}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setSelectedApplication(null)}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
export default ApplicationsGrid;
