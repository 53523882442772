import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  AppBar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllApplications } from "../api";
import { CorporationGrid } from "./CorporationGrid";
import { VendorApplicationsGrid } from "./VendorApplicationsGrid";

function AdminHome() {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    let isCancelled = false;
    async function fetchApplications() {
      const applicationsData = await getAllApplications(); // Here is the change
      if (!isCancelled) {
        setApplications(applicationsData);
      }
      setLoading(false);
    }

    fetchApplications();

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h1" gutterBottom sx={{ textAlign: "center" }}>
        Administrator Dashboard
      </Typography>
      <AppBar position="static">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="admin tabs"
          scrollButtons="auto"
        >
          <Tab
            label="Applications"
            sx={{
              color: "grey",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Corporations"
            sx={{
              color: "grey",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Vendors"
            sx={{
              color: "grey",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        </Tabs>
      </AppBar>
      <Box sx={{ py: 4 }}>
        {tabValue === 0 && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Vendor Name
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Corporation Name
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Corporation Website
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Starts
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          US Citizen
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          NAICS
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Certificates
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Qualifications
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {applications.map((application) => (
                        <TableRow key={application.id}>
                          <TableCell>{application.vendorname}</TableCell>
                          <TableCell>{application.corporation_name}</TableCell>
                          <TableCell>
                            {application.corporation_website}
                          </TableCell>
                          <TableCell>
                            {application.slot.substring(
                              0,
                              application.slot.length - 3
                            )}
                          </TableCell>
                          <TableCell>
                            {application.us_citizen ? "Yes" : "No"}
                          </TableCell>
                          <TableCell>{application.naics?.join(", ")}</TableCell>
                          <TableCell>
                            {application.certificatesDetails?.map(
                              (certificate) => (
                                <a
                                  href={certificate.url}
                                  key={certificate.name}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  View Certificates
                                </a>
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {application.qualificationsDetails?.map(
                              (qualification) => (
                                <a
                                  href={qualification.url}
                                  key={qualification.name}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  View Qualifications
                                </a>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}
        {tabValue === 1 && <CorporationGrid />}
        {tabValue === 2 && <VendorApplicationsGrid />}
      </Box>
    </Container>
  );
}

export default AdminHome;
