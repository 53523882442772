import axios from "axios";
import { redirect } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (error.response.status === 401) {
      throw new Error("Unauthorized");
    } else {
      return Promise.reject(error);
    }
  }
);

export async function adminLogin(email, password, setAuthState) {
  const response = await axios.post(`${API_BASE_URL}/administrators/login`, {
    email,
    password,
  });

  if (response.data && response.data.token) {
    setAuthState({
      isAuthenticated: true,
      userType: "Admin",
    });
  }
  return response.data;
}

export async function getCurrentUser() {
  const token = localStorage.getItem("token");

  const response = await axios.get(`${API_BASE_URL}/user/me`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const jello = [response.data.user].map((a) => {
    return {
      ...a,
      certificatesDetails: a.certificates
        ? a.certificates.map((c) => {
            return { name: c, url: `${API_BASE_URL}/files/${c}` };
          })
        : [],
      qualificationsDetails: a.qualifications
        ? a.qualifications.map((c) => {
            return { name: c, url: `${API_BASE_URL}/files/${c}` };
          })
        : [],
    };
  });

  return jello[0];
}

export function logout(setAuthState) {
  localStorage.removeItem("token");

  setAuthState({
    isAuthenticated: false,
    userType: null,
  });

  return redirect("/");
}
export async function vendorLogin(email, password, setAuthState) {
  const response = await axios.post(`${API_BASE_URL}/vendors/login`, {
    email,
    password,
  });

  if (response.data && response.data.token) {
    setAuthState({
      isAuthenticated: true,
      userType: "Vendor",
    });
  }

  return response.data;
}

export async function vendorRegistration(formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await axios.post(
    `${API_BASE_URL}/vendors/register`,
    formData,
    config
  );
  return response.data;
}

export async function corporationLogin(email, password, setAuthState) {
  const response = await axios.post(`${API_BASE_URL}/corporations/login`, {
    email,
    password,
  });

  if (response.data && response.data.token) {
    setAuthState({
      isAuthenticated: true,
      userType: "Corporation",
    });
  }
  return response.data;
}

export async function corporationRegistration(formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await axios.post(
    `${API_BASE_URL}/corporations/register`,
    formData,
    config
  );
  return response.data;
}

export async function getCorporations() {
  const token = localStorage.getItem("token");

  const response = await axios.get(`${API_BASE_URL}/corporations`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.map((a) => {
    return {
      ...a,
      logoDetails: a.logo
        ? [{ name: "Logo", url: `${API_BASE_URL}/files/${a.logo}` }]
        : [],
    };
  });

  // return response.data;
}

export async function updateVendorDetails(formData) {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/form-data",
    },
  };

  const response = await axios.put(
    `${API_BASE_URL}/vendors/profile`,
    formData,
    config
  );
  return response.data;
}

export async function fetchReservedSlots(corporationId) {
  const token = localStorage.getItem("token");

  const response = await axios.get(
    `${API_BASE_URL}/applications/slots/reserved`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: { corporationId },
    }
  );

  return response.data;
}

export async function getApplications(corporationId) {
  const token = localStorage.getItem("token");

  const response = await axios.get(
    `${API_BASE_URL}/applications/corporation/${corporationId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return response.data.map((a) => {
    return {
      ...a,
      certificatesDetails: a.certificates
        ? a.certificates.map((c) => {
            return { name: c, url: `${API_BASE_URL}/files/${c}` };
          })
        : [],
      qualificationsDetails: a.qualifications
        ? a.qualifications.map((c) => {
            return { name: c, url: `${API_BASE_URL}/files/${c}` };
          })
        : [],
    };
  });
}

export async function getApplicationsById(vendorId) {
  const token = localStorage.getItem("token");

  const response = await axios.get(
    `${API_BASE_URL}/vendors/applications/${vendorId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return response.data.map((a) => {
    return {
      ...a,
      certificatesDetails: a.certificates
        ? a.certificates.map((c) => {
            return { name: c, url: `${API_BASE_URL}/files/${c}` };
          })
        : [],
      qualificationsDetails: a.qualifications
        ? a.qualifications.map((c) => {
            return { name: c, url: `${API_BASE_URL}/files/${c}` };
          })
        : [],
    };
  });
}

export async function getApplicationsByVendor() {
  const token = localStorage.getItem("token");

  const response = await axios.get(`${API_BASE_URL}/vendors/booking`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
}

export async function createApplication(slot, token, vendor, corporation) {
  const response = await axios.post(
    `${API_BASE_URL}/vendors/create-application`,
    {
      slot,
      vendor,
      corporation,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response.data.map((a) => {
    return {
      ...a,
      certificatesDetails: a.certificates
        ? a.certificates.map((c) => {
            return { name: c, url: `${API_BASE_URL}/files/${c}` };
          })
        : [],
      qualificationsDetails: a.qualifications
        ? a.qualifications.map((c) => {
            return { name: c, url: `${API_BASE_URL}/files/${c}` };
          })
        : [],
    };
  });
}

export async function updateApplicationStatus(applicationId, status) {
  const token = localStorage.getItem("token");

  const response = await axios.put(
    `${API_BASE_URL}/applications/${applicationId}`,
    {
      status,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return response.data;
}

export async function deleteApplications(id) {
  const token = localStorage.getItem("token");

  const response = await axios.delete(
    `${API_BASE_URL}/vendors/application/${id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return response.data;
}

export async function uploadFileService(file) {
  const token = localStorage.getItem("token");

  const response = await axios.post(`${API_BASE_URL}/files/`, file, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function getFileService(fileName) {
  const token = localStorage.getItem("token");

  const response = await axios.get(`${API_BASE_URL}/files/${fileName}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export async function updateCorporationDetails(formData) {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/form-data",
    },
  };

  const response = await axios.put(
    `${API_BASE_URL}/corporations/profile`,
    formData,
    config
  );
  return response.data;
}

export async function administratorRegistration(formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await axios.post(
    `${API_BASE_URL}/administrators/register`,
    formData,
    config
  );
  return response.data;
}

export async function getAllApplications() {
  const token = localStorage.getItem("token");

  const response = await axios.get(`${API_BASE_URL}/applications/all`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.map((a) => {
    return {
      ...a,
      certificatesDetails: a.certificates
        ? a.certificates.map((c) => {
            return { name: c, url: `${API_BASE_URL}/files/${c}` };
          })
        : [],
      qualificationsDetails: a.qualifications
        ? a.qualifications.map((c) => {
            return { name: c, url: `${API_BASE_URL}/files/${c}` };
          })
        : [],
    };
  });
}

export async function getVendors() {
  const token = localStorage.getItem("token");

  const response = await axios.get(`${API_BASE_URL}/vendors`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
}
